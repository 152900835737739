import React, { useMemo } from "react";
import { TritronikInputText, TritronikSelect } from "components";
import { Formik } from "formik";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import FormItem from "./../FormItem";
import { history } from "utils";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { InputSwitch } from "primereact/inputswitch";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Firewall Alias Name is required.")
    .matches(/^(\S+$)/g, 'Name should not have whitespace.'),
  content: Yup.string().required("Firewall Alias Content is required."),
});

const FirewallAliasForm = ({ data, typeOptions, onSubmit }) => {
  const initialValues = useMemo(
    () => ({
      enabled: data?.enabled ?? true,
      name: data?.name ?? "",
      type: data?.type ?? "host",
      content: data?.content ?? "",
      description: data?.description ?? "",
    }),
    [data]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <div className="position-relative">
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="3">
                  <h3 className="mt-md-2 font-weight-medium">
                    General Configuration
                  </h3>
                </Col>
                <Col md="9">
                  <FormItem label="Enable">
                    <InputSwitch
                      checked={values.enabled}
                      onChange={(e) => setFieldValue("enabled", e.value)}
                    />
                  </FormItem>
                  <FormItem label="Name" required>
                    <TritronikInputText name="name" small />
                  </FormItem>
                  <FormItem label="Type">
                    <TritronikSelect
                      name="type"
                      value={values.type}
                      options={typeOptions}
                      onChange={(e) => setFieldValue("type", e.value)}
                      invalid={touched.type && errors.type}
                      error={errors.type}
                    />
                  </FormItem>
                  <FormItem label="Content" required>
                    <TritronikInputText name="content" small helperText="Use comma as a separator"/>
                  </FormItem>
                  <FormItem label="Description">
                    <TritronikInputText name="description" small />
                  </FormItem>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Save and Cancel Buttons */}
          <Card
            className="mb-0 position-fixed rounded-0"
            style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}
          >
            <CardBody className="d-flex justify-content-end" style={{ gap: 8 }}>
              <Button
                color="secondary"
                size="sm"
                className="px-5"
                onClick={history.goBack}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="sm"
                className="px-5"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </CardBody>
          </Card>
        </div>
      )}
    </Formik>
  );
};

FirewallAliasForm.propTypes = {
  data: PropTypes.object,
  typeOptions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};

export default FirewallAliasForm;
