import { PageContent } from "components";
import React from "react";
import PageToolbar from "../component/PageToolbar";
import { useSdwanReference } from "../hooks/useSdwanReference";
import { toast } from "react-toastify";
import { history } from "utils";
import { useParams } from "react-router";
import FirewallAliasForm from "../component/Form/FirewallAliasForm";
import { useUpdateFirewallAlias } from "../hooks/useUpdateFirewallAlias";
import { useGetDeviceFirewallAliasByIdQuery } from "../services";

const EditFirewallAliasConfiguration = () => {
  const { sn, uuid } = useParams();
  const { data } = useGetDeviceFirewallAliasByIdQuery({ sn, uuid }, { skip: !sn && !uuid });
  const { options: typeOptions } = useSdwanReference("fw-alias-type");
  const { update } = useUpdateFirewallAlias(sn);

  const onUpdate = async (values) => {
    await update(values);
    toast.success("Command to update Firewall Alias sent.");
    history.goBack();
  };


  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title="Update Firewall Alias" className="mb-3" />
      <FirewallAliasForm
        data={data}
        typeOptions={typeOptions}
        onSubmit={onUpdate}
      />
    </PageContent>
  );
};

export default EditFirewallAliasConfiguration;
