import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import OnlineStatus from "./OnlineStatus";
import { PrimeSearchInput } from "components";
import { RefreshButton } from "components/atoms/RefreshButton";
import { customStyles } from "utils/datatableStyles";
import { history } from "utils";
import { useDeleteGateway } from "../hooks/useDeleteGateway";
import { useParams } from "react-router";
import DeviceGatewayFilter from "./Filter/DeviceGatewayFilter";
import { useDeviceGatewayFilter } from "../hooks/useDeviceGatewayFilter";
import { useDeviceFirewallAliasDatatable } from "../hooks/useDeviceFirewallAliasDatatable";

const FirewallAliasesTabPanel = () => {
  const { sn } = useParams();
  const deleteGateway = useDeleteGateway(sn);
  const [smartSearch, setSmartSearch] = useState("");
  const [selectedRuleCreatedBy, setSelectedRuleCreatedBy] = useState("User");
  const { filters } = useDeviceGatewayFilter();
  const dt = useDeviceFirewallAliasDatatable({
    sn,
    smartSearch: smartSearch && smartSearch !== "" ? smartSearch : undefined,
    name: filters.gw_name ?? undefined,
    status: filters.gw_status ?? undefined,
    created_by: selectedRuleCreatedBy.toLocaleLowerCase(),
  });

  const columns = useMemo(() => {
    const renderStatus = (row) => {
      return <OnlineStatus type={row.enabled ? "enabled" : "disabled"} />;
    };

    const renderAction = (data) => {
      const openConfiguration = () =>
        history.push(
          `/admin/sdwan/device/${sn}/gateways/configurations/configuration/${data.id}`
        );

      return (
        <div className="mr-auto">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <i className="fas fa-ellipsis-v" style={{ fontSize: 14 }}></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={openConfiguration} disabled>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => deleteGateway(data)} disabled>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    };

    return [
      {
        id: "enabled",
        name: "Status".toLocaleUpperCase(),
        selector: (row) => row.enabled,
        sortable: true,
        sortField: "enabled",
        cell: renderStatus,
        width: "100px",
      },
      {
        id: "name",
        name: "Name".toLocaleUpperCase(),
        selector: (row) => row.name,
        sortable: true,
        sortField: "name",
      },
      {
        id: "type",
        name: "Type".toLocaleUpperCase(),
        selector: (row) => row.type,
        sortable: true,
        sortField: "type",
      },
      {
        id: "description",
        name: "description".toLocaleUpperCase(),
        selector: (row) => row.description,
        sortable: true,
        sortField: "description",
      },
      {
        id: "content",
        name: "content".toLocaleUpperCase(),
        selector: (row) => row.content,
        sortable: true,
        sortField: "content",
      },
      {
        id: "currentItems",
        name: "Loaded Entries".toLocaleUpperCase(),
        selector: (row) => row.currentItems,
        sortable: true,
        sortField: "currentItems",
      },
      {
        id: "lastUpdated",
        name: "Last Updated".toLocaleUpperCase(),
        selector: (row) => row.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [deleteGateway, sn]);

  const createGateway = () => {
    // history.push(`/admin/sdwan/device/${sn}/gateways/configurations/create`);
  };

  return (
    <div>
      <div
        className="d-flex flex-wrap align-items-center justify-content-between pb-3 border-0 flex-md-nowrap"
        style={{ columnGap: 4 }}
      >
        <div className="d-flex justify-content-start align-items-top">
          <h4 className="text-muted mb-0" style={{ paddingTop: "8px" }}>
            Rules created by
          </h4>
          <UncontrolledDropdown>
            <DropdownToggle
              nav
              style={{ paddingLeft: "10px", paddingTop: "8px" }}
            >
              <h4 className="text-default mb-0">
                {selectedRuleCreatedBy}
                <i
                  className="fa fa-chevron-down fa-1x ml-2"
                  style={{ fontSize: "12px" }}
                ></i>
              </h4>
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "120px", overflowY: "auto" }}>
              {["User", "System"].map((item, id) => (
                <DropdownItem
                  key={id}
                  onClick={() => setSelectedRuleCreatedBy(item)}
                >
                  <span className="text-dark">{item}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className="d-flex flex-wrap align-items-center">
          <PrimeSearchInput
            onFilter={(text) => setSmartSearch(text)}
            className="flex-1"
            size="sm"
            tooltip="Press 'Enter' to search."
            tooltipOptions={{ position: "top" }}
          />
          <DeviceGatewayFilter sn={sn} />
          <RefreshButton onClick={dt.refetch} isLoading={dt.isFetching} />
          <Button
            size="md"
            color="primary"
            onClick={createGateway}
            style={{ borderRadius: "10px" }}
          >
            Create
          </Button>
        </div>
      </div>

      <div>
        <DataTable
          data={dt.currentData}
          defaultSortFieldId="statusTranslated"
          defaultSortAsc={false}
          responsive={true}
          columns={columns}
          persistTableHead
          noHeader
          progressPending={dt.isLoading || dt.isFetching}
          pagination
          paginationServer
          paginationTotalRows={dt.totalRows}
          paginationPerPage={dt.pageSize}
          onChangePage={dt.onChangePage}
          onChangeRowsPerPage={dt.onChangeRowsPerPage}
          sortServer
          onSort={dt.onSort}
          customStyles={{
            ...customStyles,
            tableWrapper: {
              style: {
                minHeight: "400px",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default FirewallAliasesTabPanel;
